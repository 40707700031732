import React, { useState } from 'react';
import './App.css';
import { database } from '../../config/firebase';
import { ref, push, child, update } from "firebase/database";
import moment from 'moment';

function Landing() {
  const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [contactNumber, setContactNumber] = useState("");
  const [enquiry, setEnquiry] = useState(""); 
	const [validates, setvalidates] = useState(false);
  const [preferableTime, setPrefableTime] = useState(""); 
  const [preferableTime1, setPrefableTime1] = useState("");

  let validate = (text) => {
		console.log(text);
		let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
		if(reg.test(text) === false)
		{
		setEmail(text);
		setvalidates(false);
		return false;
		  }
		else {
		  setEmail(text);
		  setvalidates(true);
		}
		}

	let handleEmail = e => {
		validate(e.target.value);
	  }

  let submit = e => {
		e.preventDefault();
		if(name === ""){
		  alert("Please enter name.");
		}
	else if(contactNumber === ""){
			alert("Please enter Contact Number.");
		  }
		else if(email === ""){
			alert("Please enter email.");
		  }
		  else if(validates === false){
			alert('Email is badly formatted.');
		  }
		  else if(contactNumber.length < 10){
			alert("Please enter 10 digit Contact Number.");
		  }
		  else if(enquiry === ""){
			alert("Please enter enquiry.");
		  }
	  else{

      const newPostKey = push(child(ref(database), 'contactForm')).key;
	  
		const postData = {
name: name,
email: email,
contactNumber: contactNumber,
enquiry: enquiry,
preferableTime: preferableTime,
preferableTime1: preferableTime1,
attendAt: "",
attendBy: "",
policyTerm: "",
otherInformation: "",
timeStamp: moment().unix()
		};
	  
		const updates = {};
		updates['/contactForm/' + newPostKey] = postData;
	  
		return update(ref(database), updates).then(() => {
		  alert(`Thank you \n Your form was submitted successfully!`);
      window.location.reload();
			});
    }
  }

  return (
    <div className='body'>
   
    <div>
      <div className="wave"></div>
      <div className="wave"></div>
      <div className="wave"></div>
   </div>

    <div className="bodys">
    <div className="header copyrights-text">
      <button type="button" className="btn btn-outline-primary btn-btn-sm float-right text-white buttonColor" data-toggle="modal" data-target="#modalCenter">Request Call Back</button>
     
     </div>

    {/*} <a>
  <span>HOVER ME</span>
  <div class="waves"></div>
</a>*/}

<div className="modal fade" id="modalCenter" tabindex="-1" role="dialog" aria-labelledby="modalCenterTitle" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLongTitle">Request Call Back</h5>
             <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        
      </div>
      <div className="container-fluid">

      <small className="form-text text-muted">Kindly submit this form with the requested details. Our representatives will get in touch with you at the earliest.</small>
</div>
      <div className="modal-body">

              <form>
              
  <div className="form-group">
    <label for="exampleInputEmail1">Name<b className="text-danger">*</b></label>
    <input type="text" className="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter name" value={name} onChange={(event) => { setName( event.target.value ); }}/>
   </div>

   <div className="form-group">
    <label for="exampleInputEmail1">Contact Number<b className="text-danger">*</b></label>
    <input type="tel" minLength="9" maxLength="11" className="form-control" id="inputMobile" aria-describedby="mobileHelp" placeholder="Enter mobile number" value={contactNumber} onChange={(event) => { setContactNumber( event.target.value ); }}/>
    
    <small id="mobileHelp" className="form-text text-muted">We'll never share your contact number with anyone else.</small>
  </div>

  <div className="form-group">
    <label for="exampleInputEmail1">Email address</label>
    <input type="email" className="form-control" id="inputEmail" aria-describedby="emailHelp" placeholder="Enter email" value={email} autoCapitalize="none" onChange={handleEmail}/>
    <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small>
  </div>

  <div className="form-group">
  <label for="exampleInputEmail1">Preferable Time</label>
  <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-around'}}>
    <div style={{width: '45%'}}>
  <select className="form-control" value={preferableTime}  onChange={(event) => { setPrefableTime( event.target.value ); }}>
  <option>Choose</option>
       <option value="Week days">Week days</option>
        <option value="Week end">Week end</option>
        <option value="Any Time">Any day</option>
      </select>
      </div>
      <div style={{width: '45%'}}>
      <select className="form-control" value={preferableTime1} onChange={(event) => { setPrefableTime1( event.target.value ); }}>
      <option>Choose Time</option>
<option value="09:00 AM">09:00 AM</option>
       <option value="10:00 AM">10:00 AM</option>
       <option value="11:00 AM">11:00 AM</option>
       <option value="12:00 PM">12:00 PM</option>
       <option value="01:00 PM">01:00 PM</option>
       <option value="02:00 PM">02:00 PM</option>
       <option value="03:00 PM">03:00 PM</option>
       <option value="04:00 PM">04:00 PM</option>
       <option value="05:00 PM">05:00 PM</option>
       <option value="06:00 PM">06:00 PM</option>
       <option value="07:00 PM">07:00 PM</option>
       <option value="08:00 PM">08:00 PM</option>
       <option value="09:00 PM">09:00 PM</option>
      </select>
      </div>
      </div>
</div>

  <div className="form-group">
    <label for="formControlTextarea">Enquiry<b className="text-danger">*</b></label>
    <textarea className="form-control" id="formControlTextarea" rows="3" value={enquiry} onChange={(event) => { setEnquiry( event.target.value ); }}/>
  </div>

</form>

      </div>
      <div className="modal-footer">
      <button type="button" className="btn btn-primary" value="Submit" onClick={submit}>Request Call Back</button>
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>
      <div className="snow">
        <div className="logo-title">
            <div className="logo">
                <h1 className="text-center">
                    <img className="logo" src={require("./img/image.png")}/>
                </h1>
                <h2 className="title text-center">
                    +91 94459 00083
                </h2>
                          
               </div>
            </div>
            
        </div>
        <div className="footer copyrights-text">
            <p className="">Policy Bros © 2024. All Rights Reserved.</p>
        </div>
    </div>
  </div>
  );
}

export default Landing;
