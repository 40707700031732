import React from 'react';
import {BrowserRouter as Router , Route, Routes} from 'react-router-dom';
import Landing from './component/Landing/landing';
import Login from './component/Admin/login';
import ForgotPassword from './component/Admin/forgotPassword';
import ContactView from './component/Admin/contactView';

function App() {
  return (
    <Router>
    <Routes>
    <Route exact path="/" element={<Landing/>} /> 
    <Route exact path="/forgotPassword" element={<ForgotPassword/>} /> 
    <Route exact path="/contactView" element={<ContactView/>} /> 
    <Route exact path="/adminpage" element={<Login/>} /> 
    </Routes>
    </Router>
  );
}

export default App;
