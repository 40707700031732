import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import './login.css';

function Login() {

  const [email, setEmail] = useState("");
  const [validates, setvalidates] = useState(false);
  const [adminEmail, setAdminEmail] = useState("");
  const [showPassword, setShowPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);
  const [alertMessage, setAlertMessage] = useState(false);
  const [errorMessageAuth, setErrorMessageAuth] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);
  const [passwordMessage, setPasswordMessage] = useState(false);

  const [userAgent, setUserAgent] = useState(navigator.userAgent);

const navigate = useNavigate();

useEffect(() => { 
  const getUserData = async () => {
    await onAuthStateChanged(auth, (user) => { 
      if(user){
        console.log(user.uid);
        window.location.href = '/contactView'; 
          }
          else {
            
          }
        })
   }
     getUserData(); 

},[]);

let validate = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
  if(reg.test(text) === false)
  {
  setEmail(text);
  setvalidates(false);
  return false;
    }
  else {
    setEmail(text);
    setvalidates(true);
  }
  }

  let showPasswords = e => {
    setShowPassword(!showPassword);
  }

  let handleEmail = e => {
    validate(e.target.value);
    setEmailMessage(false);
    setEmailValidation(false);
    setErrorMessageAuth('');
  }

  let handlePassword = e => {
    setPassword( e.target.value );
    setPasswordMessage(false);
    setErrorMessageAuth('');
  }

let submit = e => {
  if(email == ''){
    setEmailMessage(true);
  }
  else if(validates === false){
    setEmailValidation(true);
}
else if(password.length == ''){
  setPasswordMessage(true);
}
    else {
   signInWithEmailAndPassword(auth, email, password).then(function(user) {
       console.log(auth.currentUser.uid);
       }).catch(function(error) {
        setErrorMessageAuth(error.message);
       }); 
    }
}

let handleKeypress = e => {
if (e.key == 'Enter') {
  submit();
}
};
 

return (
  <div className='body'>
  <section className="signin">
    <div style={{padding: 0,
  marginLeft: "auto",
  marginRight:"auto",
  textAlign: "center",
  marginTop: "175px"}}>
	   <img src={require("./../images/image.png")} style={{width: "350px", height: "200px", verticalAlign: "text-top", marginRight: "2px"}}/>
	</div>
   <br></br>        
  <div className="Doctor_login_page">

<div className="Doctor_login_page_logindetails" style={{alignItems: "center", justifyContent: 'center'}}>
<div className="container">

<div className="f_form">
					<div className="f_form_main">
          <div style={{textAlign: 'center'}}>
						<h3>Sign in!</h3>
            </div>
	
							<div className="f_form_div">
								<input type="email" name="email" placeholder="Email Address" value={email} autoCapitalize="none" onChange={handleEmail} onKeyPress={handleKeypress} required/>
							</div>

							<div className="f_form_div">
								<input type="text" name="password" value={password} onChange={handlePassword} onKeyPress={handleKeypress} placeholder="Password" required/>
							</div>

              <br></br>
            {errorMessage == true?
        <div className='alert alert-danger alert-dismissible'>
        <button type='button' className='close' onClick={() => setErrorMessage(false)}>&times;</button>
<strong>Warning!</strong> Email or Password is entered wrongly.
</div>
             :
             null
             }

{alertMessage == true?
<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setAlertMessage(false)}>&times;</button>
<strong>Warning!</strong> Current Email is not a superadmin.
</div>
            
             :
             null
             }

       {passwordMessage === true?
  
  <div className='alert alert-danger alert-dismissible'>
          <button type='button' className='close' onClick={() => setPasswordMessage(false)}>&times;</button>
<strong>Warning!</strong> Please enter password.
</div>
:
null
}

{emailValidation == true?
 
 <div className='alert alert-danger alert-dismissible'>
         <button type='button' className='close' onClick={() => setEmailValidation(false)}>&times;</button>
<strong>Warning!</strong> Email is badly formatted.
</div>
:
null
}

{emailMessage == true?
     
 <div className='alert alert-danger alert-dismissible'>
         <button className='close' type='button' onClick={() => setEmailMessage(false)}>&times;</button>
<strong>Warning!</strong> Email is empty.
</div>

:
null
}

{errorMessageAuth == ''?
null    
:

<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setErrorMessageAuth('')}>&times;</button>
{errorMessageAuth}
</div>
}

<div className="" style={{paddingBottom: '10px', float: 'right'}}>
                <a onClick={() => navigate("/forgotPassword")} className="font" style={{color:'gray', cursor: 'pointer'}}>Forgot password?</a>
             </div>

							<div className="f_form_btn" style={{alignContent: 'center'}}>
								<input type="submit" name="" value="Sign in" onClick={submit}/>
							</div>
					
					</div>
				</div>

</div>
</div>

<div className="footer-section copyrights-text">

            <p className="" style={{color: '#000'}}>&emsp;Copyright © 2024 policybros.in. All Rights Reserved.</p>
        
        </div>
</div>   

  </section>
  </div>
    );
}
export default Login;
