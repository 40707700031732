import React, { useState, useEffect } from 'react';
import { Link, Navigate, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebase';
import './login.css';
import { sendPasswordResetEmail, onAuthStateChanged } from "firebase/auth";

const d = new Date();
let year = d.getFullYear();

function ForgotPassword() {

  const [yearFetch, setYearFetch] = useState(year);

  const [email, setEmail] = useState("");
  const [validates, setvalidates] = useState(false);
  const [errorMessageAuth, setErrorMessageAuth] = useState('');
  const [emailValidation, setEmailValidation] = useState(false);
  const [emailMessage, setEmailMessage] = useState(false);

  const [buttonEnable, setButtonEnable] = useState(false);

const  navigate = useNavigate();

useEffect(() => { 
  const getUserData = async () => {
    await onAuthStateChanged(auth, (user) => { 
      if(user){
        console.log(user.uid);
          }
        })
   }
     getUserData(); 

},[]);

let validate = (text) => {
  console.log(text);
  let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/ ;
  if(reg.test(text) === false)
  {
  setEmail(text);
  setvalidates(false);
  return false;
    }
  else {
    setEmail(text);
    setvalidates(true);
  }
  }

  let handleEmail = e => {
    validate(e.target.value);
    setEmailMessage(false);
    setEmailValidation(false);
    setErrorMessageAuth('');
  }

let submit = e => {
  if(email == ''){
    setEmailMessage(true);
  }
  else if(validates === false){ 
    setEmailValidation(true);
}
    else {
      sendPasswordResetEmail(auth, email)
      .then(function () {
        alert('Email sent to Your email account!!!');
          setButtonEnable(false);
          window.location.href = '/';
      }).catch(function (e) {
        setButtonEnable(false);
          console.log(e)
          setErrorMessageAuth(e)
      }) 
    }
}

let handleKeypress = e => {
  //it triggers by pressing the enter key
if (e.key == 'Enter') {
  submit();
}
};
 
return (
  <section className="signin">

<div style={{padding: 0,
  marginLeft: "auto",
  marginRight:"auto",
  textAlign: "center",
  marginTop: "200px"}}>
	   <img src={require("../images/image.png")} style={{width: "80px", height: "80px", verticalAlign: "text-top", marginRight: "2px"}}/>
</div>
   <br></br>   

  <div className="Doctor_login_page">
<div className="Doctor_login_page_logindetails">
<div className="container">

<div className="f_form">
					<div className="f_form_main">
						<h3>Sign in!</h3>
	
							<div className="f_form_div">
								<input type="email" name="email" placeholder="Email Address" value={email} autoCapitalize="none" onChange={handleEmail} onKeyPress={handleKeypress} required/>
							</div>
              <br></br>

{emailValidation == true?
 
 <div className='alert alert-danger alert-dismissible'>
         <button type='button' className='close' onClick={() => setEmailValidation(false)}>&times;</button>
<strong>Warning!</strong> Email is badly formatted.
</div>
:
null
}

{emailMessage == true?
     
 <div className='alert alert-danger alert-dismissible'>
         <button className='close' type='button' onClick={() => setEmailMessage(false)}>&times;</button>
<strong>Warning!</strong> Email is empty.
</div>

:
null
}

{errorMessageAuth == ''?
null    
:

<div className='alert alert-danger alert-dismissible'>
<button type='button' className='close' onClick={() => setErrorMessageAuth('')}>&times;</button>
{errorMessageAuth}
</div>
}
<div className="" style={{paddingBottom: '10px', float: 'right'}}>
                <a onClick={() => navigate("/adminpage")} className="font" style={{color:'gray', cursor: 'pointer'}}>Back to Login</a>
             </div>

							<div className="f_form_btn">
								<input type="submit" name="" value="Send Link" onClick={submit}/>
							</div>
					
					</div>
				</div>

</div>
</div>

<div className="footer-section copyrights-text">

            <p className="" style={{color: '#fff'}}>&emsp;© Copyright 2021 - {yearFetch}, The Company of Four | All Rights Reserved</p>
        
        </div>
</div>      
  </section>
    );
}
export default ForgotPassword;
