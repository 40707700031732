import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getAuth } from 'firebase/auth';

var config = {
  apiKey: "AIzaSyAwatDIwYo3neeDCq8D2FSA1Rpp6XvwPHY",
  authDomain: "policy-bro-s.firebaseapp.com",
  databaseURL: "https://policy-bro-s-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "policy-bro-s",
  storageBucket: "policy-bro-s.appspot.com",
  messagingSenderId: "52744907662",
  appId: "1:52744907662:web:1aba9f7d3c89a2640d52df",
  measurementId: "G-5T9ZMJSTHL"
};

  const app = initializeApp(config);

const db = getFirestore(app);
    const database = getDatabase(app);
    const auth = getAuth(app);
    export {database, auth, db};
 
