import React, { useState, useEffect } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import { auth, database } from '../../config/firebase';
import { ref, onValue, update } from "firebase/database";
import { onAuthStateChanged, signOut } from "firebase/auth";
import './contactView.css';

function ContactView() {

    const [datas, setDatas] = useState([]);
    const [term, setTerm] = useState("");
    const [urlData, setUrlData] = useState("");
    const [attendedBy, setAttendedBy] = useState("");
    const [attendedData, setAttendedData] = useState("");
    const [attendedByData, setAttendedByData] = useState("");
    const [attendedInformation, setAttendedInformation] = useState("");
    const [valueTo, setValueTo] = useState("");
    const [otherInformations, setOtherInformations] = useState("");
    const navigate = useNavigate();

    const [adminEmail, setAdminEmail] = useState("");
    const [adminName, setAdminName] = useState("");
  
    useEffect(() => { 
     const getUserData = async () => {
        await onAuthStateChanged(auth, (user) => { 
          if(user){
              }
              else {
                window.location.href = '/adminpage'; 
              }
            })
       }
         getUserData();
    
    },[]);

      useEffect(() => { 
    const getSideData = async () => {
      let sidebar = document.querySelector(".sidebar");
      let sidebarBtn = document.querySelector(".sidebarBtn");
      sidebarBtn.onclick = function() {
        sidebar.classList.toggle("active");
        if(sidebar.classList.contains("active")){
        sidebarBtn.classList.replace("bx-menu" ,"bx-menu-alt-right");
      }else
        sidebarBtn.classList.replace("bx-menu-alt-right", "bx-menu");
      }
    }
     getSideData(); 

},[]);   

    useEffect(() => { 
        const getUserData = async () => {

            const starCountRef = ref(database, 'contactForm/');
            onValue(starCountRef, async snapshot => {
                let returnArray = [];
             await snapshot.forEach(element => {
                var item = {};
                item.key = element.key;
                item.name = element.val().name;
                item.email = element.val().email;
                item.contactNumber = element.val().contactNumber;
                item.enquiry = element.val().enquiry;
                item.timeStamp = element.val().timeStamp;
                item.attendAt = element.val().attendAt;
                item.attendBy = element.val().attendBy;
                item.preferableTime = element.val().preferableTime;
                item.preferableTime1 = element.val().preferableTime1;
                item.otherInformation = element.val().otherInformation;
                returnArray.push(item);
            });
            console.table(returnArray);
            setDatas(returnArray);
              

            });         
         }
           getUserData(); 

    },[]); 

    let logOut = e => {
      signOut(auth);
       window.location.href = '/adminpage';
       }

       let dateFormatss = (date) => {
        var data = moment.unix(date).format("DD-MM-YYYY hh:mm:ss A");
       // var data = moment.unix(date).format('dddd, MMMM Do, YYYY h:mm:ss A')
        return data;
      }

      let submit = e => {
        e.preventDefault();
        update(ref(database, `/contactForm/${attendedData}`), {
          attendBy: attendedBy,
          attendAt: moment().unix(),
          otherInformation: otherInformations
        }).then(() => {
          alert(`Details updated!`);
          window.location.reload();
          });
      }

    return (
      <section>
      <div className="sidebar">
     
      <div className="logo-details">&nbsp;
      <img src={require("../images/image.png")} style={{width: '150px', height: '100px', objectFit: 'contain'}} alt=""/> &nbsp;
         </div>
        <ul className="nav-links">
          <li>
         
            <a>
          &emsp;  <i className="fa-sharp fa-solid fa-warehouse" style={{color: '#FFFF00'}}></i> &nbsp; &nbsp;
              <span className="links_name">Dashboard</span>
            </a>
   
          </li>

          <li className="log_out">
          <a onClick={logOut} style={{cursor: "pointer"}}>
          &emsp;  <i className="fa-sharp fa-solid fa-right-from-bracket" style={{color: '#FFFF00'}}></i> &nbsp; &nbsp;
              <span className="links_name">Log out</span>
            </a>
          </li>
        </ul>
    </div>
    <section className="home-section">
      <nav>
        <div className="sidebar-button">
          <i className='bx bx-menu sidebarBtn'></i>
        </div>
        <div className="search-box">
          <input type="text" style={{fontSize: '14px'}} placeholder="Search by Name & Email & Contact Number..." onChange={(event) => { setTerm( event.target.value ); }} value={term}/>
        </div>
        <div className="profile-details">
        <img src={require("./../images/image.png")} style={{width: '80px', height: '50px', objectFit: 'contain' }} alt=""/>
          <span className="admin_name">&emsp;Admin</span>
        </div>
      </nav>
  
      <div className="home-content">

      <div className='search_result_list my-2'>
            
            <div className="table-responsive mb-2" style={{borderRadius: '10px',border:'0.5px solid #ccc',borderCollapse: 'collapse'}}>
         <table className='table table-borderless mb-0' style={{border:'none', background: "white"}}>
         <thead className="text-center" style={{backgroundColor:'#00529C', color:'#fff'}}>
           <tr>
             <th style={{fontSize: '16px'}}>Name</th>
             <th style={{fontSize: '16px'}}>Email</th>
             <th style={{fontSize: '16px'}}>Contact Number</th>
             <th style={{fontSize: '16px'}}>Enquiry</th>
             <th style={{fontSize: '16px'}}>Enquired On</th>
             <th style={{fontSize: '16px'}}>Attended By</th>
             <th style={{fontSize: '16px'}}>Attended On</th>
             <th style={{fontSize: '16px'}}>Preferable Time</th>
             <th style={{fontSize: '16px'}}>Action</th>
           </tr>
           </thead>
           <tbody>
            {datas.filter(x => x.name.toLowerCase().includes(term.toLowerCase()) || x.email.toLowerCase().includes(term.toLowerCase()) || x.contactNumber.toLowerCase().includes(term.toLowerCase())).map((persons,index) =>
    
           <tr key={index}>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.name}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.email}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.contactNumber}</td>
                 <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.enquiry}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{dateFormatss(persons.timeStamp)}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.attendBy}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{dateFormatss(persons.attendAt)}</td>
             <td style={{verticalAlign: 'middle', fontSize: '14px'}}>{persons.preferableTime} {persons.preferableTime1}</td>
             <td style={{verticalAlign: 'middle'}}>
              {persons.attendAt == ""?
             <button className='btn btn-sm btn-primary' data-placement="bottom" title="Update Details" data-toggle="modal" data-target="#viewReportModal" 
             onClick={() => {
              setAttendedData(persons.key); setValueTo(false)}}>Update Details</button>
              :
              <button className='btn btn-sm btn-primary' data-placement="bottom" title="View Details" data-toggle="modal" data-target="#viewReportModal" 
              onClick={() => {
                setAttendedByData(persons.attendBy); setAttendedInformation(persons.otherInformation); setValueTo(true)}}>View Details</button>
             }
             </td>
           </tr>
       
          )}
</tbody>
</table>
       </div>
        </div>

        <div className="modal fade bd-example-modal-lg" id="viewReportModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-width" role="document">
              <div className="modal-content">
                    <div className="modal-header">
                      <h5 className="modal-title" id="exampleModalLabel">Update Details</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>  
      
                    
                    <div className="modal-body" style={{overflowY: 'scroll', minHeight: '450px', maxHeight: '450px', width: '100%'}}>

            {valueTo == false?
                   <form>
              
              <div className="form-group">
                <label for="exampleInputEmail1">Attended By<b className="text-danger">*</b></label>
                <select className="form-control" value={attendedBy} onChange={(event) => { setAttendedBy( event.target.value ); }}>
  <option>Choose</option>
       <option value="Sanjay">Sanjay</option>
        <option value="Sakthi">Sakthi</option>
      </select>
                 </div>

                 <div className="form-group">
    <label for="formControlTextarea">Policy Information<b className="text-danger">*</b></label>
    <textarea className="form-control" id="formControlTextarea" rows="3" value={otherInformations} onChange={(event) => { setOtherInformations( event.target.value ); }}/>
  </div>
</form>            
:
<form>
              
<div className="form-group">
  <label for="exampleInputEmail1">Attended By</label>

<input type="text" className="form-control" id="inputName" aria-describedby="nameHelp" placeholder="Enter name" value={attendedByData} />
 
   </div>

   <div className="form-group">
<label for="formControlTextarea">Policy Information</label>
<textarea className="form-control" id="formControlTextarea" rows="3" value={attendedInformation} />
</div>
</form>  
}
             </div>
                
                    <div className="modal-footer">
                      {valueTo == false?
                    <button type="button" className="btn btn-primary" value="Submit" onClick={submit}>Update</button>
                    :
                    <></>
                      }
                      <button type="button" className="btn btn-danger" data-dismiss="modal">Close</button>
              
                    </div>
                  </div>
              </div>
              </div>

          </div>
       

        </section> 
</section>
        );

    }
    export default ContactView;